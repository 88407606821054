<template>
  <div>
     <Row>
      <i-col span="24" class="text-right">
        <i-button type="primary" class="m-r-5" size="small" @click="handleSubmit(3)">保存并上架</i-button>
        <i-button type="primary" class="m-r-5" size="small" @click="handleSubmit(1)">保存</i-button>
      </i-col>
    </Row>
    <Form ref="investmentModel" :model="investmentModel" autocomplete="on" name="oohforce-device-add" id="oohforce-device-add"
      :rules="ruleValidate" label-position="top">
      <div style="font-size:14px;line-height:16px;margin:10px 0px;">项目需求</div>
      <Row :gutter="8" class="m-b-10">

        <i-col span="5">
          <FormItem label="项目名称">
            <Input v-model="investmentModel.name" size="small" placeholder="请输入项目名称" />
          </FormItem>
        </i-col>
        <i-col span="5">
          <FormItem label="业态规划">
            <Input v-model="investmentModel.industryName" size="small" placeholder="请输入业态规划" />
          </FormItem>
        </i-col>
        <i-col span="5">
          <FormItem  label="招租方式">
            <i-select v-model="investmentModel.rentType" filterable size="small" placeholder="请选择招租方式">
              <i-option v-for="item in rentTypeArray" :value="item.id" :key="'st_' + item.id">{{ item.name }}</i-option>
            </i-select>
          </FormItem>
        </i-col>
        <i-col span="5" >
          <FormItem label="起租时长（月）">
            <InputNumber v-model="investmentModel.tenancy" style="width:100%"  size="small" placeholder="请输入起租时长" />
          </FormItem>
        </i-col>

        <i-col span="4">
          <FormItem  label="免租时间（月）">
            <InputNumber v-model="investmentModel.freePeriod" style="width:100%" size="small"  placeholder="请输入免租时间" />
          </FormItem>
        </i-col>
        <i-col span="5" >
          <FormItem label="起始单价（元/㎡·月）">
            <InputNumber v-model="investmentModel.rentAmount" style="width:100%" size="small"  placeholder="请输入起始单价" />
          </FormItem>
        </i-col>
        <i-col span="5" >
          <FormItem label="保证金（元）">
            <InputNumber v-model="investmentModel.depositAmount" :min="0"  style="width:100%" size="small" placeholder="请输入保证金" />
          </FormItem>
        </i-col>
        <i-col span="5">
          <FormItem label="违约金（元）">
            <InputNumber v-model="investmentModel.penaltyAmount" :min="0" style="width:100%" size="small" placeholder="请输入违约金" />
          </FormItem>
        </i-col>

        <i-col span="5">
          <FormItem label="滞纳金（元）">
            <InputNumber v-model="investmentModel.delayAmount"  :min="0" style="width:100%" size="small" placeholder="请输入滞纳金" />
          </FormItem>
        </i-col>
        <i-col span="4">
          <FormItem label="施工押金（元）">
            <InputNumber v-model="investmentModel.constructionAmount" :min="0" style="width:100%" size="small" placeholder="请输入施工押金" />
          </FormItem>
        </i-col>
        <i-col span="5">
          <FormItem label="物业费（元/㎡·月）">
            <InputNumber v-model="investmentModel.propertyAmount"  :min="0" style="width:100%" size="small" placeholder="请输入物业费" />
          </FormItem>
        </i-col>
        <i-col span="5">
          <FormItem label="能耗费（元/㎡·月）">
            <InputNumber v-model="investmentModel.energyAmount"  :min="0" style="width:100%" size="small" placeholder="请输入能耗费" />
          </FormItem>
        </i-col>
        <i-col span="5">
          <FormItem label="合同模板" >
            <i-select v-model="investmentModel.templateId" filterable size="small" placeholder="请选择合同模板">
              <i-option v-for="item in templateArray" :value="item.id" :key="'dt_' + item.id">{{ item.name }}
              </i-option>
            </i-select>
          </FormItem>
        </i-col>
        <i-col span="4">
          <FormItem label="租赁开始时间" >
            <DatePicker size="small" transfer :show-week-numbers="true" v-model="rentStartDate" style="width: 100%" placeholder="选择租赁开始时间"></DatePicker>
          </FormItem>
        </i-col>

      </Row>
    </Form>
    <Row>
      <i-col span="24" class="m-b-5">
        <a style="float: right; line-height: 30px" @click="handleChoiceDevices()">+添加资产</a
        >
      </i-col>

    </Row>
    <Table
      size="small"
      :columns="columnManagedata"
      :data="tableData"
      stripe
    ></Table>
    <choiceDeviceModal  ref="deviceChoice"></choiceDeviceModal>
  </div>
</template>

<script>
// import { getPositions } from '@/api/product/resource'
// import { addDevice, updateDevice } from '@/api/product/deviceedit'

import choiceDeviceModal from './choiceDevice.vue'
import { ParseDate } from '@/utils/dateFormat'
/** new api */
import { editInvestmentProject, getInvestmentProject, listDevice, deleteDevice, editDevice } from '@/api/contract/invest'
export default {
  components: {
    choiceDeviceModal
  },
  props: {

  },
  data () {
    return {
      rentStartDate: '',
      investmentModel: {
        name: '',
        industryName: '',
        rentType: '',
        tenancy: null,
        freePeriod: null,
        rentAmount: null,
        depositAmount: null,
        penaltyAmount: null,
        delayAmount: null,
        constructionAmount: null,
        propertyAmount: null,
        energyAmount: null,
        templateId: '',
        rentStartDate: null,
        deviceIds: ''
      },
      ruleValidate: {
        stationId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        code: [
          { required: true, pattern: '[a-z]|[0-9]', message: ' ', trigger: 'blur' }
        ],
        deviceBigmodel: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        investmentModel: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        position: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        floor: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        manageUnit: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        assetPackage: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ]
      },

      tableData: [],
      columnManagedata: [
        { title: '编号', key: 'innerCode' },
        { title: '类型', align: 'center', key: 'deviceTypeName' },
        { title: '站点', align: 'center', key: 'stationName' },
        { title: '楼层', align: 'center', key: 'floor' },
        { title: '位置', align: 'center', key: 'positionName' },
        { title: '面积（㎡）', align: 'center', key: 'area' },
        { title: '经营方', align: 'center', ellipsis: true, tooltip: true, key: 'manageUnitName' },

        {
          title: '通电',
          align: 'center',
          key: 'electric',
          render: (h, params) => {
            return h('span', params.row.electric ? '是' : '否')
          }
        },
        {
          title: '通水',
          align: 'center',
          key: 'waterSupply',
          render: (h, params) => {
            return h('span', params.row.waterSupply ? '是' : '否')
          }
        },
        {
          title: '通气',
          align: 'center',
          key: 'gasSupply',
          render: (h, params) => {
            return h('span', params.row.gasSupply ? '是' : '否')
          }
        },
        {
          title: '消防验收',
          align: 'center',
          key: 'fireApproval',
          render: (h, params) => {
            return h('span', params.row.fireApproval ? '是' : '否')
          }
        },

        {
          title: '备注说明',
          align: 'center',
          key: 'content'
        },
        {
          title: '操作',
          align: 'center',
          width: 120,
          render: (h, params) => {
            const detailButton = h(
              'a',
              {
                style: {
                  marginRight: '5px',
                  color: 'red'
                },
                on: {
                  click: () => {
                    deleteDevice({ deId: params.row.id, investId: this.investmentId }).then(res => {
                      this.initDeviceList()
                    })
                  }
                }
              },
              '移除'
            )
            const editButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    let content = ''
                    this.$Modal.confirm({
                      title: '备注说明',
                      render: h => {
                        return h('Input', {
                          props: {
                            type: 'textarea',
                            value: content,
                            placeholder: '请输入资产招商备注说明'
                          },
                          on: {
                            'on-change': event => {
                              content = event.target.value
                            }
                          }

                        })
                      },
                      onOk: () => {
                        editDevice({ deId: params.row.id, investId: this.investmentId, content: content }).then(res => {
                          if (res && !res.errcode) {
                            this.$Notice.success({ desc: '调整备注说明成功' })
                            // window.location.reload()
                            this.initDeviceList()
                          }
                        })
                      }
                    })
                  }
                }
              },
              '调整备注'
            )

            return h('div', [editButton, detailButton])
          }
        }
      ]
    }
  },
  mounted () {
  },
  created () {
    if (this.investmentId) {
      getInvestmentProject({ investId: this.investmentId }).then(res => {
        this.investmentModel.name = res.name
        this.investmentModel.constructionAmount = res.constructionAmount
        this.investmentModel.delayAmount = res.delayAmount
        this.investmentModel.energyAmount = res.energyAmount
        this.investmentModel.freePeriod = parseInt(res.freePeriod || 0)
        this.investmentModel.industryName = res.industryName
        this.investmentModel.penaltyAmount = res.penaltyAmount
        this.investmentModel.propertyAmount = res.propertyAmount
        this.investmentModel.rentAmount = res.rentAmount
        if (res.rentStartDate) {
          this.investmentModel.rentStartDate = res.rentStartDate
          this.rentStartDate = res.rentStartDate
        }
        this.investmentModel.rentType = res.rentType + ''
        this.investmentModel.templateId = res.templateId + ''
        this.investmentModel.tenancy = res.tenancy
        this.investmentModel.depositAmount = res.depositAmount
        this.initDeviceList()
        // listDevice({ investId: this.investmentId }).then(res => {
        //   this.$store.commit('set_investment_chkDeviceArray', res.map(item => { return item.deviceId }))
        //   // this.choiceDeviceIdArray = res.map(item => { return item.deviceId })
        // })
      })
    }
  },
  methods: {
    initDeviceList () {
      listDevice({ investId: this.investmentId }).then(res => {
        this.tableData = res
        this.$store.commit('set_investment_chkDeviceArray', res.map(item => { return item.deviceId }))
      })
    },
    handleSubmit (saveType) {
      this.$refs.investmentModel.validate((valid) => {
        if (valid) {
          this.$Modal.confirm({
            title: '操作提示',
            content: '请确认项目信息填写无误？',
            onOk: () => {
              if (this.investmentId) {
                this.investmentModel.id = this.investmentId
              }
              this.investmentModel.createType = saveType
              // this.$store.commit('set_investment_pageType', 'list')
              if (this.rentStartDate) {
                this.investmentModel.rentStartDate = ParseDate(this.rentStartDate)
              } else {
                this.investmentModel.rentStartDate = null
              }
              // this.investmentModel.deviceIds = this.choiceDeviceIdArray.toString()
              editInvestmentProject(this.investmentModel).then(res => {
                if (res.success) {
                  this.$Notice.success({ desc: '操作成功' })
                  this.$store.commit('set_investment_pageType', 'list')
                  this.$store.commit('set_investment_update', new Date())
                }
              })
            }
          })
        } else {
          this.$Notice.error({ desc: '请正确输入信息' })
        }
      })
    },
    handleChoiceDevices () {
      this.$refs.deviceChoice.showModal()
    }
  },
  watch: {
    beginUpdate () {
      this.initDeviceList()
    }
  },
  computed: {
    rentTypeArray () {
      return this.$store.state.investmentManage.rentTypeArray
    },
    templateArray () {
      return this.$store.state.investmentManage.templateArray
    },
    investmentId () {
      return this.$store.state.investmentManage.investmentId
    },
    choiceDeviceIdArray () {
      return this.$store.state.investmentManage.chkDeviceArray
    },
    beginUpdate () {
      return this.$store.state.investmentManage.beginUpdate
    }
  }
}
</script>
